import React from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Form as BootstrapForm, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default class SignupForm extends React.Component {
    // Get tomorrow's date at 3 PM
    getDefaultDateTime = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(15, 0, 0, 0);
        return tomorrow;
    };

    // Get minimum date (1 hour from now)
    getMinDateTime = () => {
        const minDate = new Date();
        minDate.setHours(minDate.getHours() + 1);
        return minDate;
    };

    // Filter available times based on selected date
    filterTime = (time) => {
        const selectedDateTime = new Date(time);
        const minDateTime = this.getMinDateTime();

        // If selected date is same as min date
        if (selectedDateTime.toDateString() === minDateTime.toDateString()) {
            return selectedDateTime >= minDateTime;
        }

        // For future dates, allow all times
        return true;
    };

    validationSchema = Yup.object({
        name: Yup.string()
            .required('Name is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        phone: Yup.string()
            .matches(/^\+?[\d\s-()]{10,}$/, 'Invalid phone number format')
            .required('Phone number is required'),
        phoneList: Yup.string()
            .required('Phone number list is required')
            .test('valid-phones', 'Invalid phone numbers in list', value => {
                if (!value) return false;
                const phones = value.split('\n').filter(p => p.trim());
                return phones.every(phone =>
                    /^\+?[\d\s-()]{10,}$/.test(phone.trim())
                );
            }),
        dateTime: Yup.date()
            .min(this.getMinDateTime(), 'Must be at least 1 hour from now')
            .required('Date and time are required'),
        audioFile: Yup.mixed()
            .required('Audio file is required')
            .test('fileType', 'Invalid file type (must be .wav, .mp3, or .m4a)',
                value => {
                    if (!value) return false;
                    return [
                        'audio/wav',
                        'audio/mpeg',
                        'audio/mp4',
                        'audio/x-m4a',
                    ].includes(value.type);
                })
    });

    handleSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            const formData = new FormData();

            const formattedDate = values.dateTime.toISOString().split('T')[0];
            const formattedTime = values.dateTime.toTimeString().slice(0, 5);

            Object.entries(values).forEach(([key, value]) => {
                if (value) formData.append(key, value);
            });

            formData.append('date', formattedDate);
            formData.append('time', formattedTime);

            const response = await fetch('/api/create-checkout-session', {
                method: 'POST',
                body: formData
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to create checkout session');
            }

            window.location.href = data.url;

        } catch (error) {
            console.error('Form submission error:', error);
            setErrors({ submit: error.message });
        } finally {
            setSubmitting(false);
        }
    };

    render() {
        const style = {
            button: {
                'backgroundColor': '#00DC93',
            },
            text: {
                'backgroundColor': '#080E10',
                'borderColor': '#A1A4A5',
                'color': 'white'
            },
            caption: {
                'color': '#845AFF',
                'fontSize': 'small'
            },
            datePicker: {
                width: '100%',
                '& input': {
                    backgroundColor: '#080E10',
                    borderColor: '#A1A4A5',
                    color: 'white',
                    width: '100%',
                }
            },
            error: {
                color: 'red',
                fontSize: '0.875rem',
                marginTop: '0.25rem'
            }
        };

        return (
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    phoneList: '',
                    dateTime: this.getDefaultDateTime(),
                    audioFile: null
                }}
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}
            >
                {({ errors, touched, setFieldValue, values, isSubmitting }) => (
                    <Form noValidate>
                        <center>
                            Please fill out the form below and upload an audio file.
                            The voicemail recording will be sent to the phone numbers
                            listed and show up as a missed call.
                        </center>
                        <br />

                        <BootstrapForm.Group className="mb-3">
                            <BootstrapForm.Label>
                                <sup>*</sup> Your Name:
                            </BootstrapForm.Label>
                            <Field
                                name="name"
                                type="text"
                                className="form-control"
                                style={style.text}
                            />
                            {errors.name && touched.name && (
                                <div style={style.error}>{errors.name}</div>
                            )}
                        </BootstrapForm.Group>

                        <BootstrapForm.Group className="mb-3">
                            <BootstrapForm.Label>
                                <sup>*</sup> Your Email:
                            </BootstrapForm.Label>
                            <Field
                                name="email"
                                type="email"
                                className="form-control"
                                style={style.text}
                            />
                            {errors.email && touched.email && (
                                <div style={style.error}>{errors.email}</div>
                            )}
                        </BootstrapForm.Group>

                        <BootstrapForm.Group className="mb-3">
                            <BootstrapForm.Label>
                                <sup>*</sup> Phone Number Voicemail is From:
                            </BootstrapForm.Label>
                            <Field
                                name="phone"
                                type="text"
                                className="form-control"
                                style={style.text}
                            />
                            {errors.phone && touched.phone && (
                                <div style={style.error}>{errors.phone}</div>
                            )}
                        </BootstrapForm.Group>

                        <BootstrapForm.Group className="mb-3">
                            <BootstrapForm.Label>
                                <sup>*</sup> Phone Number List:
                            </BootstrapForm.Label>
                            <Field
                                name="phoneList"
                                as="textarea"
                                rows={3}
                                className="form-control"
                                style={style.text}
                            />
                            <BootstrapForm.Text style={style.caption}>
                                List each phone number on a new line, making sure there
                                are no empty lines.
                            </BootstrapForm.Text>
                            {errors.phoneList && touched.phoneList && (
                                <div style={style.error}>{errors.phoneList}</div>
                            )}
                        </BootstrapForm.Group>

                        <BootstrapForm.Group className="mb-3">
                            <BootstrapForm.Label>
                                <sup>*</sup> Date and Time to Send Voicemail:
                            </BootstrapForm.Label>
                            <DatePicker
                                selected={values.dateTime}
                                onChange={(date) => setFieldValue('dateTime', date)}
                                showTimeSelect
                                timeFormat="h:mm aa"
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="MMMM d, yyyy 'at' h:mm aa"
                                minDate={this.getMinDateTime()}
                                filterTime={this.filterTime}
                                className="form-control"
                                wrapperClassName="datePicker"
                                customInput={
                                    <input style={style.text} />
                                }
                                wrapperProps={{
                                    style: { width: '100%' }
                                }}
                            />
                            {errors.dateTime && touched.dateTime && (
                                <div style={style.error}>{errors.dateTime}</div>
                            )}
                        </BootstrapForm.Group>

                        <BootstrapForm.Group className="mb-3">
                            <BootstrapForm.Label>
                                <sup>*</sup> Audio File:
                            </BootstrapForm.Label>
                            <input
                                type="file"
                                onChange={(event) => {
                                    setFieldValue('audioFile',
                                        event.currentTarget.files[0]);
                                }}
                                className="form-control"
                                style={style.text}
                            />
                            <BootstrapForm.Text style={style.caption}>
                                Upload audio file (WAV, Mp3 or M4a) with voicemail message
                            </BootstrapForm.Text>
                            {errors.audioFile && touched.audioFile && (
                                <div style={style.error}>{errors.audioFile}</div>
                            )}
                        </BootstrapForm.Group>

                        {errors.submit && (
                            <div style={style.error}>{errors.submit}</div>
                        )}

                        <Button
                            style={style.button}
                            variant="custom"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Processing...' : 'Checkout'}
                        </Button>
                    </Form>
                )}
            </Formik>
        );
    }
}




