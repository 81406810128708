import React, { useEffect, useState } from 'react';

const SuccessPage = () => {
    const [status, setStatus] = useState('processing');
    const [error, setError] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);

    useEffect(() => {
        const processCampaign = async () => {
            try {
                console.log('=== Processing Campaign ===');
                const response = await fetch('/api/process-payment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                let data;
                try {
                    data = await response.json();
                } catch (parseError) {
                    throw new Error('Invalid response from server');
                }

                // Store order details regardless of success/failure
                if (data.orderDetails) {
                    setOrderDetails(data.orderDetails);
                }

                // Check for error in the response data
                if (!response.ok || data.error) {
                    throw new Error(data.error || data.message ||
                        `Server error: ${response.status} ${response.statusText}`);
                }

                // If we get here, everything is successful
                setStatus('success');

            } catch (error) {
                console.error('Campaign processing error:', error);
                const errorMessage = error.message || 'An unexpected error occurred';
                setStatus('error');
                setError(errorMessage);
            }
        };

        processCampaign();
    }, []);

    const renderOrderDetails = () => {
        if (!orderDetails) return null;

        const phoneNumbers = orderDetails.phoneList
            .split('\n')
            .filter(phone => phone.trim());

        return (
            <div style={{
                textAlign: 'left',
                margin: '40px 0',
                padding: '20px',
                border: '1px solid #A1A4A5',
                borderRadius: '4px'
            }}>
                <h3>Order Details</h3>
                <div style={{ marginLeft: '20px' }}>
                    <p><strong>Name:</strong> {orderDetails.name}</p>
                    <p><strong>Email:</strong> {orderDetails.email}</p>
                    <p><strong>From Phone Number:</strong> {orderDetails.phone}</p>
                    <p><strong>Delivery Date and Time:</strong> {
                        new Date(orderDetails.dateTime).toLocaleString()
                    }</p>
                    <p><strong>Audio File:</strong> {orderDetails.audioFileName}</p>
                    <div>
                        <strong>Phone Numbers to Call:</strong>
                        <ul style={{
                            listStyleType: 'none',
                            padding: '10px 0 0 20px',
                            margin: 0
                        }}>
                            {phoneNumbers.map((phone, index) => (
                                <li key={index}>• {phone.trim()}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    const renderStatus = () => {
        switch (status) {
            case 'processing':
                return (
                    <div style={{ textAlign: 'center' }}>
                        <h2>Processing your campaign...</h2>
                        <p>Please wait while we set up your voicemail campaign.</p>
                    </div>
                );
            case 'success':
                return (
                    <div style={{ textAlign: 'center' }}>
                        <h2>Success!</h2>
                        <p>
                            Your payment has been processed, and your voicemail
                            will be sent to the phone numbers provided at the
                            time selected.
                        </p>
                        <p>
                            Please reach out to{' '}
                            <a href="mailto:support@carroll.media"
                               style={{ color: '#00DC93' }}>
                                support@carroll.media
                            </a>
                            {' '}with any questions!
                        </p>
                    </div>
                );
            case 'error':
                return (
                    <div style={{ textAlign: 'center' }}>
                        <h2>Error Processing Campaign</h2>
                        <p style={{
                            margin: '20px 0',
                            color: '#FF4444',
                            fontFamily: 'monospace'
                        }}>
                            {error}
                        </p>
                        <p>
                            Please contact{' '}
                            <a href="mailto:support@carroll.media"
                               style={{ color: '#00DC93' }}>
                                support@carroll.media
                            </a>
                            {' '}for assistance.
                        </p>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{
            maxWidth: '600px',
            margin: '40px auto',
            padding: '0 20px'
        }}>
            {renderStatus()}
            {renderOrderDetails()}
        </div>
    );
};

export default SuccessPage;

