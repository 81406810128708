import React, {useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignupForm from "./Components/SignupForm.js";
import {Container, Row, Col} from 'react-bootstrap';
import SuccessPage from "./Components/SuccessPage.js";


export default class App extends React.Component {
state = {
  name: "",
  email: "",
  phone: "",
  campaign: "",
  audioFile: null,
  phoneList: ""
}


  handler = (event) => {
    const target = event.target;
    const name = target.name;
    this.setState({[name]: target.value});
    console.log(this.state);
  }

render() {
  const style = {
    main: {
      'padding': '5%',
      'marginRight': '20%',
      'marginLeft': '20%',
    },
    mainMobile: {
      'padding': '5%',
      'marginRight': '3%',
      'marginLeft': '3%',
    },
    title: {
      'color': '#00DC93',
      'textAlign': 'center',
      'marginBottom': '30px',
      'fontWeight': 'bold',
      'fontSize': '260%'
    },
    description: {
      'textAlign': 'center',
      'margin': '20px'
    },
    image: {
      'padding':'30px',
      'textAlign': 'center',
      'width': '200px'
    }
  }
  return (
    <BrowserRouter>
    <div style={ (screen.width > 500) ? style.main : style.mainMobile}>
      <center><img src={'/logo.png'} style={style.image}/></center>
        <div style={style.title}>
          Ringless Voicemail
        </div>
        <Row>
          <Routes>
            <Route path="/" element={<SignupForm stateChanger={this.handler}/>} />
            <Route path="/success" element={<SuccessPage globalState={this.state}/>} />
          </Routes>
        </Row>
      </div>
    </BrowserRouter>

  );
}
}
