import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import './mycssfile.css';
import { Provider } from 'react-redux';

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);